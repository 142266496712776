<template>
  <div id="main">
    <dv-loading v-if="false">Loading...</dv-loading>
    <staticHeader />
    <div class="content">
      <div class="contentTopBox">
        <div>
          <dv-border-box-1 class="flex-vertical">
            <!-- 左上角两个charts -->
            <div class="borderBox"><labelPie :text="'标签分类'" /></div>

            <!-- <div><topLeftChart1 :text="'文件类型占比图'" /></div> -->
          </dv-border-box-1>
        </div>
        <div>
          <dv-border-box-10 class="flex-vertical"> 
            <div class="borderBox"><resourcePie :text="'资源占比图'" :pieData="resourceList"/></div>

            <!-- <table2 /> -->
          </dv-border-box-10>
        </div>
        <div>
          <dv-border-box-13 class="flex-vertical">
            <!-- <span class="deep">共享量部门TOP10</span> -->
            
              <barChart />
            
            <!-- <scrollRankingBoard /> -->
          </dv-border-box-13>
          <!-- <scrollRankingBoard /> -->
        </div>
      </div>
      <div class="contentBottomBox">
        <div>
          <dv-border-box-13 class="bottomBox1">
            <fileCount />
            <Organ />
          </dv-border-box-13>
        </div>
        <div>
          <dv-border-box-3 class="bottomBox2">
            <div class="borderBox">
              <span>告警信息最新</span>
              <warnList />
            </div>
          </dv-border-box-3>

        </div>
      </div>
      <div class="contentTopBox">
        <div>
          <dv-border-box-9 class="flex-vertical">
            <!-- 左上角两个charts -->
            <div class="borderBox file-type">
              <span>文件类型</span>
              <RadarChart id='myRadarChart' title="文档情况统计"></RadarChart>
            </div>
          </dv-border-box-9>
        </div>
        <div>
          <dv-border-box-11 title="" class="flex-vertical word-cloud">
            <wordCloud :pieList="filePieList" />
          </dv-border-box-11>
        </div>
        <div>
          <dv-border-box-12 class="flex-vertical">
            <bottomRight />
          </dv-border-box-12>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import barChart from './conpontents/barChart.vue';
import Organ from './conpontents/organ.vue';
import RadarChart from './conpontents/radarChart.vue'
import staticHeader from "./conpontents/staticHeader.vue";
import resourcePie from "./conpontents/resourcePie.vue";
import labelPie from "./conpontents/labelPie.vue";
import fileCount from "./conpontents/fileCount.vue";
import bottomRight from "./conpontents/bottomRight.vue";
import scrollRankingBoard from "./conpontents/scrollRankingBoard.vue";
import wordCloud from "./conpontents/wordClond.vue";
import warnList from './conpontents/warnList.vue'
export default {
  name: "Home",
  components: {
    barChart,
    Organ,
    staticHeader,
    resourcePie,
    RadarChart,
    labelPie,
    fileCount,
    bottomRight,
    scrollRankingBoard,
    wordCloud,
    warnList
  },
  data() {
    return {
      fileType:{radius:'68%',
      activeRadius: '75%',
      data:[{name: 'txt', value: 115},{name:'pdf',value:200},{name: 'doc',value: 130},{name: 'ppt',value: 117},{name:'img',value:10},{name:'xls',value:150},{name:'png',value:330},{name:'mp4',value:30},{name:'jpg',value:300},{name:'mp3',value:50}]},
      resourceList:[{value:10,name:'已占用'},{value:100,name:'未占用'}],
      filePieList: [{ value: 335, name: '文本' },{ value: 310, name: '计算机' },
      { value: 274, name: '档案' },{ value: 204, name: '医学' },
      { value: 214, name: '标书' },{ value: 400, name: '设计' }]
    };
  },
  created() {},
  mounted() {
  },
  methods: {},
};
</script>

<style scoped lang="less">
#main {
  width: 100%;
  height: 100vh;
  // min-width: 1850px;
  background: url("../../assets/pageBg.png") no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
  ::v-deep .loading-tip {
    font-size: 23px !important;
    color: aqua;
    font-weight: bold;
  }
  .content {
    width: 100%;
    height: calc(100vh - 80px);
    padding-top: 20px;
    display: flex;
    .contentTopBox {
      // height: 55%;
      width: 25%;
      padding: 10px;
      display: grid;
      // grid-template-columns: 1fr 6.5fr 6fr 3fr;
      grid-template-columns: 1fr;
      grid-template-rows:33% 33% 33%;

      ::v-deep.flex-vertical .border-box-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        padding: 10px;
        .borderBox {
          width: 92%;
          height: 90%;
          background-color: rgba(19, 25, 47, 0.6);
          span {
            font-size:12px;
            color: #06f1ef;
            padding: 10px;
            box-sizing: border-box;
          }
        }
        .file-type {
          span {
            font-size: 14px;
            font-weight: bold;
            color: #06f1ef;
          }
          .dv-active-ring-chart {
            width: 100%;
            height: 100%;
          }
        }
        div:first-child {
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
        div:last-child {
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        }
        .dv-scroll-ranking-board {
          width: 95%;
          // background-color: rgba(19, 25, 47, 0.6);
        }
      }
      .word-cloud {
        display: flex;
        justify-content: center;
        align-items: center;
        /deep/ .border-box-content {
          width: 98%;
          height: 88%;
        }
      }
    }
    .contentBottomBox {
      // height: 45%;
      width: 50%;
      padding: 10px;
      display: grid;
      grid-template-columns:1fr;
      grid-template-rows:66% 33%;
      .bottomBox1,
      .bottomBox2 {
        padding: 10px;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        
      }
      .bottomBox1 {
        ::v-deep .border-box-content {
          display: grid;
          grid-template-columns: 1fr;
          grid-template-rows:45% 54%;
          /deep/ #myCharts1{
            width: 100%;
            height: 100%;
          }
        }
      }
      .bottomBox2 {
        ::v-deep .border-box-content {
          display: grid;
          grid-template-columns:  1fr;
          .borderBox {
            // width: 92%;
            span {
              font-size:12px;
              color: #06f1ef;
              padding: 10px;
              box-sizing: border-box;
            }
          }
        }
      }
    }
  }
}
</style>
